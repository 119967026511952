import React from "react";

import Layout from "../components/Layout/layout";
import SEO from "../components/Generic/seo";
import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from "gatsby";

import localization from "../localization/en.json";
import UnderConstruction from "../components/Generic/underconstruction";

const AboutUsPage = () => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "under_construction_bg.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="div"
          fluid={imageData}
          backgroundColor={`#0a0a0d`}
        >
          <Layout localization={localization}>
            <SEO title="Veselka Works LLC - About Us" />
            <div>
              <UnderConstruction localization={localization} />
            </div>
          </Layout>
        </BackgroundImage>
      );
    }}
  />
);

export default AboutUsPage;
